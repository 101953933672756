<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain
        Equipment
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="../../assets/images/covid19-vaccine-waste.jpg"
            alt=""
            width="100%"
          />
          <figcaption
            class=""
            style="font-size: 0.8125rem;font-style: italic; color:#999"
          >
            An interruption in the required storage conditions of the vaccines
            could lead to a diminution in their potency, this is a leading cause
            of vaccine wastage.
          </figcaption>
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
          <!-- <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class="body-1 font-weight-bold">Date: April 1, 2019</p>
          </span> -->
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Reduce the Frequency of Vaccine Waste With A New And Tested Cold
            Chain Equipment
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: JUNE 13, 2021</p>
            </span>
          </div>
          <p class="pb-2">Originally from <span>techcabal</span></p>
          <p class="font-weight-bold">
            According to UNICEF, vaccines save up to 3 million lives every year.
            Vaccines are believed to be one of the greatest technologies
            tailored towards the sustenance of the human race. In the face of
            ravaging pandemics such as COVID-19, humans depend on vaccines to
            survive the wave of disease outbreaks. The COVID-19 pandemic has led
            to over 3.5 million deaths globally with its impact being felt by
            all, vaccines are touted to be mankind’s hope for survival and
            economic recovery.
          </p>
          <p class="">
            The benefits of vaccines in saving and sustaining lives can not be
            overemphasized. Especially in highly populated developing countries
            where disease outbreaks have the most impact. Vaccines, which are
            thermally reactive drugs, must be kept in an environment that allows
            for temperature control and monitoring, as they require a
            temperature of 2° C to 8° C to maintain potency. Hence, sustainable
            cold chain management systems are needed in the health industry to
            store and distribute vaccines. This is why governments and private
            health bodies invest in the procurement of effective cold chain
            equipment.
          </p>
          <p class="">
            Vaccine management involves the production, storage and
            transportation of vaccines. This process requires a cold chain
            system to retain the potency of the vaccine and prevent wastage of
            vaccines which translates to wastage of funds. Healthcare workers
            monitor the storage conditions of vaccines using special cold chain
            equipment like cold boxes and temperature monitoring devices.
          </p>
          <p>
            For best results, vaccines are stored between two degrees Celsius
            and eight degrees Celsius (2° C to 8° C). Ideally, this temperature
            must be maintained from the production area to the point where they
            are needed. Maintaining this temperature is a major challenge for
            developing countries because of the lack of infrastructure required
            to monitor and control the temperature of vaccines. An interruption
            in the required storage conditions of the vaccines could lead to a
            diminution in the potency of these vaccines, an increase in the
            spread of disease, loss of lives and resources.
          </p>
          <p>
            In a bid to create a sustainable cold chain system for the
            management of vaccines in Nigeria, a local startup known as Gricd
            Integrated Services Limited has developed a set of cold chain
            equipment for the healthcare industry. This set of equipment
            includes MOTE 50 Cold Box and the MOTE temperature. MOTE enables
            healthcare workers to detect the temperature range between +25° C to
            -30° C. Its high-tech remote function makes it possible for
            healthcare workers to monitor and control the storage conditions of
            vaccines from anywhere in the world (without physical access to the
            device) during transportation and storage. This serves as an
            effective method of maintaining the cold chain.
          </p>

          <h3 class="font-weight-bold">
            MOTE Real-Time Data Logger is one of the newly developed and tested
            cold chain equipment used to preserve vaccines in large health
            organizations such as Nigeria Centre for Disease Control (NCDC)
          </h3>

          <figure class="py-8 d-flex justify-center">
            <img
              src="../../assets/images/MOTE-Gricd_Smart_Monitoring.png"
              alt=""
              width="70%"
              class=""
            />
          </figure>

          <p>
            MOTE temperature monitoring device is powered by IoT technology and
            has a battery life of up to 3 Years. MOTE is equipped with very
            advanced features that make vaccine management less challenging by
            reducing the risks of loss of potency of vaccines and improving the
            transportation system of vaccines. The built-in alarm trigger
            feature of MOTE keeps healthcare workers more in charge of vaccines
            in the cold chain system. The built-in alarm goes off when there is
            a temperature excursion or geofence breach, sending out email and
            SMS notifications, prompting for actions that are necessary to curb
            losses.
          </p>
          <p>
            MOTE temperature monitoring device is a cold chain solution to
            vaccine wastage. Its assemblage makes it portable and easy for
            transportation. Healthcare organisations in Nigeria like NCDC,
            SYNLAB, Assene Laborex and Medvacc Pharmacy use the MOTE temperature
            monitoring devices to track real-time temperature and location of
            medical items.
          </p>
          <p>
            MOTE temperature monitoring device stores data on the temperature of
            vaccines daily thus helping healthcare workers keep and share this
            data within the organisation to keep track of each vaccine in the
            cold chain system. This new equipment developed by Gricd Integrated
            Services Limited.
          </p>
          <p>
            Schedule a free demo of the MOTE temperature monitoring device
            <router-link
              to="/contact/demo-request"
              style="color:#28a84a; text-decoration:underline !important"
            >
              here.
            </router-link>
          </p>
          <p>
            You can visit
            <router-link
              to="/"
              style="color:#28a84a; text-decoration:underline !important"
              >www.gricd.com</router-link
            >
            to find out more about Gricd Integrated Services Limited. Also,
            visit
            <router-link
              to="/cold-chain-equipments"
              style="color:#28a84a; text-decoration:underline !important"
              >www.gricd.com/cold-chain-equipments</router-link
            >
            to find out more about other cold chain equipment developed by Gricd
            Integrated Services Limited.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{hover}">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/baby vaccination.jpg';
import image5 from '../../assets/images/covid oon deep oce.jpg';
import image6 from '../../assets/images/covid19-vaccine-waste.jpg';
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content: 'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link: '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content: 'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link: '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: 'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
                item:
                  'https://gricd.com/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
</style>
